import { Component } from "react";
import Modal from 'react-foundation-modal';
import CheckboxComponent from "../utils/checkbox.component";
import axios from 'axios';
import Cookies from 'js-cookie';
import {Base64} from 'js-base64';

const overlayStyle = {
  'backgroundColor': 'rgba(0,0,0,0)'
  };



class App extends Component {
  constructor() {
    super();
    this.handleOnChange = this.handleOnChange.bind(this);
      this.state = 
      {
      checkboxList: [{},],
      heatingCheckBoxList: [{},],
      userList : '',
      revealState:{
        modalIsOpen : false
      },
      hidden : 'hide',
      Other : ''
    }; 
  }

  componentDidMount() {
    let cookieValue = Cookies.get('user') ;
    axios.post('https://portal.regional-services.com/api/getUserCN',{ //getUserTest
      num : cookieValue
    }).then((resp)=>{
      //console.log('user is '+resp.data);
      this.setState({userList : resp.data});

      axios.get('https://portal.regional-services.com/api/supCapScopes/6/'+resp.data+'').then((resp)=>{  //http://localhost:3001
          //console.log(resp.data.ScopeID); 
          
          //set the checkboxlist state with the results from the database
          this.setState({checkboxList: resp.data});
          
          //if the user has previously selected Other then check to see if they have inputted intot he freetext field.
          //The result below is when the array has been mapped through and then a filter to remove all the null entries.
          //It is then saved to state.
          let result = resp.data.map(a => a.ifOther);
          //console.log(result);
          const endresult = result.filter(element => {
            return element !== null;
          });

          this.setState({Other: endresult});
          //console.log(endresult);
          
          if(endresult.length === 0){
            this.setState({hidden: 'hide'});
          }else{
            this.setState({hidden: 'visible'});
          }

        });
        
        
        //get heating sub checkboxlist
        axios.get('https://portal.regional-services.com/api/supCapScopes/45');

      });
  }

  onChange = (event) => {
    //console.log(event); 
    this.setState({ selectedValue: event.target.value });
    /* if(event.target.value === '60'){
      console.log('other');
    } */
  };

  updateInputValue(evt) { // for Other input box
    const val = evt.target.value;
    this.setState({
      Other: val
    });
    //console.log('other is ' + this.state.Other);
  }

  handleOnChange(event,option) {
    //console.log(event.target.checked);
    let checkboxList = this.state.checkboxList;
    checkboxList.forEach(chkItem=>{
      //console.log(chkItem);
      if(chkItem === option){
        if(event.target.value === '60'){
          if(event.target.checked){
          //console.log('other');
          //reveal an input box named 'other', addClass perhaps
          this.setState({hidden: 'visible'});
          }else{
            this.setState({hidden: 'hide'});
          }
        }
        chkItem.isChecked = event.target.checked;
        chkItem.itemChecked = 0;
      }
      
    })
    this.setState({
      checkboxList: checkboxList,
      //Other: event.target.value
    });
    
  }

  

  handleOnSubmit = (status) => {
    //console.table(this.state.checkboxList);
    
    //delete existing scopes
    axios.post('https://portal.regional-services.com/api/deleteScopes', {  
         Category : 6,
         User: this.state.userList
    }).then(()=>{

   

    //map through the checkboxlist state
    this.state.checkboxList.map(d => {
      //console.log(d.ScopeID)
      //console.log(this.state.Other)
      if(d.isChecked === true || d.itemChecked === 1)
       
       axios.post('https://portal.regional-services.com/api/insertScopesB', {  
         Scope : d.ScopeID,
         Other : this.state.Other,
         Category : 6,
         User: this.state.userList
        }).then(()=>{
        //alert('successfull Insert');
        this.setState({
          revealState:{
            modalIsOpen : status
          }
        });
        });

      });
      
    
      });
      
  }

  showPopup = (status) => {
    this.setState({
      revealState:{
        modalIsOpen : status
      }
    });
  }

  
  render() {
    
      return (
        
          <div>
          <CheckboxComponent 
          checkboxList={this.state.checkboxList}  
          onChange={this.handleOnChange}
          />

          <label className={this.state.hidden}>Please Specify</label>
          <input type="text" name="Other" className={this.state.hidden} onChange={evt => this.updateInputValue(evt)} value={this.state.Other}/>

          <button className="button" onClick={()=>this.handleOnSubmit(true)}>Submit</button>

            <Modal 
                open={this.state.revealState.modalIsOpen}
                closeModal={this.showPopup}
                isModal={true}
                size="small"
                overlayStyle={overlayStyle}
                 >
                <h3>Entries Saved Successfully</h3>
                <p>Your scopes have been saved. Please make sure to check the other tabs to see if you have any other scopes in a different category.</p>
                <button className="button" type="button" onClick={() => this.showPopup(false)} >
                    Close
                </button>
            </Modal>
          </div>
          
      );
    
  }
}
export default App;