
import React from 'react';
import 'jquery';
import 'foundation-sites';
import logo from '../BAE_Logo.svg';
import era from '../era.png';

const Footer = () => {
    return (
        <div className="footer-container">
<footer className="footer">
    <div className='grid-container'>
						<div className="grid-x grid-margin-x">
                        
                        <div className="small-3 cell">
                        <h3>Prestwick</h3> 
                            <hr/>
                            <span className="address">
                            BAE Systems Air Prestwick<br/>
                            Prestwick International Airport<br/>
                            Prestwick | Ayrshire<br/>
                            KA9 2RW<br/>
                            UK
                            </span>
                        
                        </div>
                        <div className="small-3 cell">
                        <h3>Information</h3>
                            <hr/>
                            <ul class="no-bullet">
                            <li><a href="https://portal.regional-services.com/img/RA-Portal-Conditions-of-Use.pdf" target="_blank">Conditions of Use</a></li>
                            <li><a href="https://www.baesystems.com/en/privacy" target="_blank">Privacy</a></li>
                            <li><a href="https://www.baesystems.com/en/cookie-policy" target="_blank">Cookie Policy</a></li>
                            <li><a href="https://portal.regional-services.com/img/requirements.pdf" target="_blank">Technical Guide</a></li>
                            </ul>
                        </div>
                        
                        <div className="small-3 cell">
                        <h3>Stay Connected</h3>
                            <hr/>   
                            <a href="https://twitter.com/baesystemsair" target="_blank"><div className="twitter-hover social-slide"></div></a>
                            <a href="https://www.facebook.com/BAESystemsplc" target="_blank"><div className="facebook-hover social-slide"></div></a>
                            <a href="https://www.linkedin.com/company/bae-systems" target="_blank"><div className="linkedin-hover social-slide"></div></a>
                             
                            </div>   
                        </div> 
                        </div>
                    </footer>
                     
                    <section className="sub-footer">
                    <div className="grid-container">
                    <div className="grid-x grid-padding-x">
                    <div className="small-6 cell">
                    <p className="source-org copyright padding-top-extra">&copy; 2024 BAE Systems Regional Aircraft. All Rights Reserved.</p>
                    </div>
                    <div className="small-12 large-2 cell text-right padding-top padding-bottom">
                        <img src={logo} alt="BAE Systems"/>
                        </div>
                    </div>
                    </div>
                    </section>
</div>
                    );
    };
    
export default Footer;