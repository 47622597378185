//file: src/components/Header.js
import React from 'react';
import 'jquery';
import 'foundation-sites';
import logo from '../BAE_Logo.svg';


const Header = () => {
    return (
        <header>

        <nav className="top-bar topbar-responsive">
            <div className="top-bar-title">
                
                <a className="topbar-responsive-logo" href="#"><strong>Supplier <span>Capability</span></strong></a>
            </div>
            <div id="topbar-responsive" className="topbar-responsive-links">
                <div className="top-bar-right">
                    <ul className="menu simple vertical medium-horizontal">

                        
                        <li><img className="header-logo" width="100px;" height="15px;" src={logo} alt="bae systems" /></li>
                    </ul>
                </div>
            </div>
        </nav>

        

    </header>
        );
    };
    
export default Header;