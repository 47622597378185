import React from "react";
class CheckboxComponent extends React.Component {

  render() {
    const checkboxList = this.props.checkboxList;
    //console.log(checkboxList);
    return (
      <>
        {checkboxList.map(option => ( 
          
          <div className="form-check" key={option.ScopeID}>
           
            <input
            className="form-check-input"
              type="checkbox"
              name="scope"
              /* key={option.ScopeID} */ 
              id={option.ScopeID}
              value={option.ScopeID}
              defaultChecked={option.itemChecked ? 1 : 0}  
              checked={option.isChecked}  
              onChange={(e) => this.props.onChange(e,option)}
            />
            <label className="form-check-label" htmlFor={option.ScopeID}>
              {option.Scope}
            </label>
          </div>
          
        ))}
      </>
    );
  }
}
export default CheckboxComponent;