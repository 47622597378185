import { Component } from "react";
import 'jquery';
import 'foundation-sites';
import { Tabs, TabItem, TabsContent, TabPanel } from "react-foundation";
import axios from 'axios';
import Cookies from 'js-cookie';
import Category0 from './main_category';
import Category7 from './sub_category';
import Category1 from './conventional_machining';
import Category2 from './nc_machining';
import Category3 from './thread_machining';
import Category4 from './additive_manufacture';
import Category5 from './NADCAP';
import Category6 from './NDT_methods';


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 1,
      start : this.props.dataFromApp,
      user : 0,
      userId : [{},],
      access : [{},],
      cn : ''
    };  
  }

  componentDidMount() {
    let cookieValue = Cookies.get('user') ;
    //this.setState({cn : this.props.dataFromApp});
    this.setState({start : cookieValue});
    axios.post('https://portal.regional-services.com/api/getUserCN',{ //getUserTest
      num : cookieValue
      //app : 16
    }).then((resp)=>{
      this.setState({user : resp.data});
      //Get users ID
      axios.post('https://portal.regional-services.com/api/getUserDetails',{
        user: resp.data
      }).then((respB)=>{
        this.setState({userId: respB.data});
        axios.post('https://portal.regional-services.com/api/getUserAppAccess',{
        userid: respB.data,
        app : 9
        }).then((respC)=>{
          this.setState({access: respC.data});
          
        });
      });
    });

    
    
  }

  render() {
    /*console.log('user is '+ this.state.start);
    console.log('user is '+ this.state.user);
    console.log('user id is '+this.state.userId);
    console.log('user access is '+this.state.access); */
    return (
        <div id="tab-container">
        <Tabs data-auto-focus="false" data-options="scroll_to_content: true">
					<TabItem isActive={this.state.activeIndex === 1} onClick={(e) => { this.setState({ activeIndex: 1 }) }}><a href="#">Main Category</a></TabItem>
          <TabItem isActive={this.state.activeIndex === 8} onClick={(e) => { this.setState({ activeIndex: 8 }) }}><a href="#">Sub Category</a></TabItem>
					<TabItem isActive={this.state.activeIndex === 2} onClick={(e) => { this.setState({ activeIndex: 2 }) }}><a href="#">Conventional Machining</a></TabItem>
					<TabItem isActive={this.state.activeIndex === 3} onClick={(e) => { this.setState({ activeIndex: 3 }) }}><a href="#">NC Machining</a></TabItem>
          <TabItem isActive={this.state.activeIndex === 4} onClick={(e) => { this.setState({ activeIndex: 4 }) }}><a href="#">Thread Machining</a></TabItem>
          <TabItem isActive={this.state.activeIndex === 5} onClick={(e) => { this.setState({ activeIndex: 5 }) }}><a href="#">Additive Manufacture</a></TabItem>
          <TabItem isActive={this.state.activeIndex === 6} onClick={(e) => { this.setState({ activeIndex: 6 }) }}><a href="#">NADCAP</a></TabItem>
          <TabItem isActive={this.state.activeIndex === 7} onClick={(e) => { this.setState({ activeIndex: 7 }) }}><a href="#">NDT Methods</a></TabItem>
				</Tabs>
				<TabsContent>
					<TabPanel id={'tab2'} isActive={this.state.activeIndex === 1} ><Category0></Category0></TabPanel>
          <TabPanel id={'tab8'} isActive={this.state.activeIndex === 8} ><Category7></Category7></TabPanel>
					<TabPanel id={'tab2'} isActive={this.state.activeIndex === 2} ><Category1></Category1></TabPanel>
					<TabPanel id={'tab3'} isActive={this.state.activeIndex === 3} ><Category2></Category2></TabPanel>
          <TabPanel id={'tab4'} isActive={this.state.activeIndex === 4} ><Category3></Category3></TabPanel>
          <TabPanel id={'tab5'} isActive={this.state.activeIndex === 5} ><Category4></Category4></TabPanel>
          <TabPanel id={'tab6'} isActive={this.state.activeIndex === 6} ><Category5></Category5></TabPanel>
          <TabPanel id={'tab7'} isActive={this.state.activeIndex === 7} ><Category6></Category6></TabPanel>
				</TabsContent>
        </div>
    );
  }
}
export default App;