import React, { useEffect, useState } from "react";
import 'jquery';
import 'foundation-sites';
import Header from './components/header';
import Footer from './components/footer';
//import Scopes from './components/scopeForm';
import TabsB from './components/tabs';
//import Auth from './components/authB';
import Bounce from 'react-reveal/Bounce'; // Importing Zoom effect
import Cookies from 'js-cookie';
import axios from 'axios';
import './assets/App.css';
import ReactGA from 'react-ga';


function App() {
  
  const TRACKING_ID = "350955170"; // OUR_TRACKING_ID
  ReactGA.initialize(TRACKING_ID);
  const [loggedIn, setLoggedIn] = useState('');
  const [user, setUser] = useState('');
  const [userId, setUserId] = useState('');
  const [start, setStart] = useState('');

  useEffect(() => {
    // Define the string
    let encodedStringAtoB = Cookies.get('user') ; 
    if(encodedStringAtoB == null) {
      setLoggedIn('false');
      
    }else{
      setStart(encodedStringAtoB);
      axios.post('https://portal.regional-services.com/api/getUserCN',{
          num : encodedStringAtoB
        }).then((resp)=>{
          setUser(resp.data);
          axios.post('https://portal.regional-services.com/api/getUserDetails',{
            user: resp.data
          }).then((respB)=>{
            setUserId(respB.data);
            axios.post('https://portal.regional-services.com/api/getUserAppAccess',{
            userid: respB.data,
            app : 9
            }).then((respC)=>{
              setLoggedIn(respC.data);
            });
          });
        });
    }
  });

  if(loggedIn === 'false'){
    
    return <div className="App">
    <div id="colorlib-page">
      <div id="container-wrap">
        <Header></Header>
        <div id="colorlib-main">
        <section className="home-bg">
      <div className="grid-x grid-padding-x small-padding-collapse">
          <div className="large-2 medium-2 small-12 cell sidebar">
              <div className="user-card">
                  <ul className="no-bullet">
                      <li><i className="fi-torso"></i> <a className="sidelink" href="https://portal.regional-services.com">Back to Portal</a></li>
                  </ul>
              </div>
              
          </div>
          <div className="large-10 medium-10 small-12 cell sup_cap_bg">
              <div className="grid-container fluid padding-top">
                  
                  <div className="grid-x grid-padding-x">
                      <div className="small-12 medium-9 cell">
                      <h2>Access Denied</h2>
                      <p>Please login to the Regional Aircraft Portal in order to use Supplier Capability.</p>
                      </div>
                  
                  </div>
              </div>
              </div>
      </div>
      </section> 
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
    
  }else{
//console.log('user is '+ start);
   return (
      <div className="App">
        <div id="colorlib-page">
          <div id="container-wrap">
            <Header></Header>
            <div id="colorlib-main">
            <section className="home-bg">
          <div className="grid-x grid-padding-x small-padding-collapse">
              <div className="large-2 medium-2 small-12 cell sidebar">
                  <div className="user-card">
                      <ul className="no-bullet">
                          <li><i className="fi-torso"></i> <a className="sidelink" href="https://portal.regional-services.com">Back to Portal</a></li>
                      </ul>
                  </div>
                  
              </div>
              <div className="large-10 medium-10 small-12 cell sup_cap_bg">
                  <div className="grid-container fluid padding-top">
                      
                      <div className="grid-x grid-padding-x">
                          <div className="small-12 medium-11 cell">
                          <h2>Welcome to Supplier Capability</h2>
                          {/* <Auth></Auth> */}
                          
                          <p>Supplier Capability is a form that allows users to select scopes that are relevant to your company.</p>
                          <p>Scopes are categorised into the tabs below. Once selected and submitted the data is then sent to BAE Systems.</p>
                          <p>Users can update their selections at any time.</p>
                          <Bounce bottom>
                          <div><TabsB dataFromApp = {start}></TabsB></div>
                          </Bounce>
                          </div>
                      
                      </div>
                  </div>
                  </div>
          </div>
          </section> 
            </div>
          </div>
        </div>
        <Footer></Footer>
      </div>
    );
  }
}

export default App;
