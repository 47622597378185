import { Component } from "react";
import Modal from 'react-foundation-modal';
import CheckboxComponent from "../utils/checkbox.component";
import axios from 'axios';
import Cookies from 'js-cookie';
import {Base64} from 'js-base64';

const overlayStyle = {
  'backgroundColor': 'rgba(0,0,0,0)'
  };



class App extends Component {
  constructor() {
    super();
    this.handleOnChange = this.handleOnChange.bind(this);
      this.state = 
      {
      checkboxList: [{},],
      heatingCheckBoxList: [{},],
      heatingCheckBoxListHidden: '',
      weldingCheckBoxList: [{},],
      weldingCheckBoxListHidden: '',
      coatingsCheckBoxList: [{},],
      coatingsCheckBoxListHidden: '',
      chemicalProcessingCheckBoxList: [{},],
      chemicalProcessingCheckBoxListHidden: '',
      materialTestingCheckBoxList: [{},],
      materialTestingCheckBoxListHidden: '',
      userList : '',
      revealState:{
        modalIsOpen : false
      },
      hidden : 'hide',
      Other : ''
    }; 
    
  }

  componentDidMount() {
    let cookieValue = Cookies.get('user') ;
    axios.post('https://portal.regional-services.com/api/getUserCN',{ //getUserTest
      num : cookieValue
    }).then((respUser)=>{
      this.setState({userList : respUser.data});
      axios.get('https://portal.regional-services.com/api/supCapScopes/5/'+respUser.data+'').then((resp)=>{  //http://localhost:3001
        
        //set the checkboxlist state with the results from the database
        this.setState({checkboxList: resp.data});
        
        /***If the Option Other has been selected***
        
        if the user has previously selected Other then check to see if they have inputted intot he freetext field.
        The result below is when the array has been mapped through and then a filter to remove all the null entries.
        It is then saved to state. */

        let result = resp.data.map(a => a.ifOther);
        //console.log(result);
        const endresult = result.filter(element => {
          return element !== null;
        });

        this.setState({Other: endresult});
        
        if(endresult.length === 0){
          this.setState({hidden: 'hide'});
        }else{
          this.setState({hidden: 'visible'});
        }
        /***End of Other ***/


        /*Check to see if any of the checkboxes which have sub checkboxes under them have been checked.
        If they have then set their subcheck box div to visible by default on page load.
        If not then hide them.
        */

        const data = resp.data.filter(check => check.itemChecked === 1);
        //console.log(testA);

        const heatTreatment = obj => obj.ScopeID === 45;
        if(data.some(heatTreatment) === true)
          this.setState({heatingCheckBoxListHidden: 'visible'});
        else
          this.setState({heatingCheckBoxListHidden: 'hide'}); 
        
        const welding = obj => obj.ScopeID === 50;
        if(data.some(welding) === true)
          this.setState({weldingCheckBoxListHidden: 'visible'});
        else
          this.setState({weldingCheckBoxListHidden: 'hide'}); 
        
        const coatings = obj => obj.ScopeID === 42;
        if(data.some(coatings) === true)
          this.setState({coatingsCheckBoxListHidden: 'visible'});
        else
          this.setState({coatingsCheckBoxListHidden: 'hide'});
        
        const chemProcessing = obj => obj.ScopeID === 41;
        if(data.some(chemProcessing) === true)
          this.setState({chemicalProcessingCheckBoxListHidden: 'visible'});
        else
          this.setState({chemicalProcessingCheckBoxListHidden: 'hide'});
        
        const matTesting = obj => obj.ScopeID === 46;
        if(data.some(matTesting) === true)
          this.setState({materialTestingCheckBoxListHidden: 'visible'});
        else
          this.setState({materialTestingCheckBoxListHidden: 'hide'});
        
        /*Sub Checkbox Lists*/
         //get heating sub checkboxlist
         axios.get('https://portal.regional-services.com/api/getSubScopes/45/'+respUser.data+'').then((resp)=>{
          //set the heating checkboxlist state with the results from the database
          this.setState({heatingCheckBoxList: resp.data});
        });
        //get welding sub checkboxlist
        axios.get('https://portal.regional-services.com/api/getSubScopes/50/'+respUser.data+'').then((resp)=>{
          //set the heating checkboxlist state with the results from the database
          this.setState({weldingCheckBoxList: resp.data});
        });
        //get coatings sub checkboxlist
        axios.get('https://portal.regional-services.com/api/getSubScopes/42/'+respUser.data+'').then((resp)=>{
          //set the heating checkboxlist state with the results from the database
          this.setState({coatingsCheckBoxList: resp.data});
        });
        //get chemical processing sub checkboxlist
        axios.get('https://portal.regional-services.com/api/getSubScopes/41/'+respUser.data+'').then((resp)=>{
          //set the heating checkboxlist state with the results from the database
          this.setState({chemicalProcessingCheckBoxList: resp.data});
        });
        //get material testing sub checkboxlist
        axios.get('https://portal.regional-services.com/api/getSubScopes/46/'+respUser.data+'').then((resp)=>{
          //set the heating checkboxlist state with the results from the database
          this.setState({materialTestingCheckBoxList: resp.data});
        });

      });
    });
  }

  onChange = (event) => {
    //console.log(event); 
    this.setState({ selectedValue: event.target.value });
    /* if(event.target.value === '61'){
      console.log('other');
    } */
  };

  updateInputValue(evt) { // for Other input box
    const val = evt.target.value;
    this.setState({
      Other: val
    });
    //console.log('other is ' + this.state.Other);
  }

  handleOnChange(event,option) {
    //console.log(event.target.checked);
    let checkboxList = this.state.checkboxList;
    let heatingCheckBoxList = this.state.heatingCheckBoxList;
    let weldingCheckBoxList = this.state.weldingCheckBoxList;
    let coatingsCheckBoxList = this.state.coatingsCheckBoxList;
    let chemicalProcessingCheckBoxList = this.state.chemicalProcessingCheckBoxList;
    let materialTestingCheckBoxList = this.state.materialTestingCheckBoxList;

    checkboxList.forEach(chkItem=>{
      //console.log(chkItem);
      if(chkItem === option){
        if(event.target.value === '61'){
          if(event.target.checked){
          //console.log('other');
          //reveal an input box named 'other', addClass perhaps
          this.setState({hidden: 'visible'});
          }else{
            this.setState({hidden: 'hide'});
          }
        }
        //if the item is 45 Heat Treatment
        if(event.target.value === '45'){
          if(event.target.checked){
            this.setState({heatingCheckBoxListHidden: 'visible'});
          }else{
            this.setState({heatingCheckBoxListHidden: 'hide'});
          }
        }
        if(event.target.value === '50'){
          if(event.target.checked){
            this.setState({weldingCheckBoxListHidden: 'visible'});
          }else{
            this.setState({weldingCheckBoxListHidden: 'hide'});
          }
        }
        if(event.target.value === '42'){
          if(event.target.checked){
            this.setState({coatingsCheckBoxListHidden: 'visible'});
          }else{
            this.setState({coatingsCheckBoxListHidden: 'hide'});
          }
        }
        if(event.target.value === '41'){
          if(event.target.checked){
            this.setState({chemicalProcessingCheckBoxListHidden: 'visible'});
          }else{
            this.setState({chemicalProcessingCheckBoxListHidden: 'hide'});
          }
        }
        if(event.target.value === '46'){
          if(event.target.checked){
            this.setState({materialTestingCheckBoxListHidden: 'visible'});
          }else{
            this.setState({materialTestingCheckBoxListHidden: 'hide'});
          }
        }
        chkItem.isChecked = event.target.checked;
        chkItem.itemChecked = 0;
      }
      
    });
    
    heatingCheckBoxList.forEach(chkItem=>{
      //console.log(chkItem);
      if(chkItem === option){
        chkItem.isChecked = event.target.checked;
        chkItem.itemChecked = 0;
      }
    });
    weldingCheckBoxList.forEach(chkItem=>{
      //console.log(chkItem);
      if(chkItem === option){
        chkItem.isChecked = event.target.checked;
        chkItem.itemChecked = 0;
      }
    });
    coatingsCheckBoxList.forEach(chkItem=>{
      //console.log(chkItem);
      if(chkItem === option){
        chkItem.isChecked = event.target.checked;
        chkItem.itemChecked = 0;
      }
    });
    chemicalProcessingCheckBoxList.forEach(chkItem=>{
      //console.log(chkItem);
      if(chkItem === option){
        chkItem.isChecked = event.target.checked;
        chkItem.itemChecked = 0;
      }
    });
    materialTestingCheckBoxList.forEach(chkItem=>{
      //console.log(chkItem);
      if(chkItem === option){
        chkItem.isChecked = event.target.checked;
        chkItem.itemChecked = 0;
      }
    });


    this.setState({
      checkboxList: checkboxList,
      heatingCheckBoxList: heatingCheckBoxList,
      weldingCheckBoxList: weldingCheckBoxList,
      coatingsCheckBoxList: coatingsCheckBoxList,
      chemicalProcessingCheckBoxList: chemicalProcessingCheckBoxList,
      materialTestingCheckBoxList: materialTestingCheckBoxList

      //Other: event.target.value
    });
    
  }

  

  handleOnSubmit = (status) => {
    //console.table(this.state.checkboxList);
    
    //delete existing scopes
    axios.post('https://portal.regional-services.com/api/deleteScopes', {  
         Category : 5,
         User: this.state.userList
    }).then(()=>{

   

    //map through the main checkboxlist state
    this.state.checkboxList.map(d => {
      if(d.isChecked === true || d.itemChecked === 1)
      axios.post('https://portal.regional-services.com/api/insertScopesB', {  
        Scope : d.ScopeID,
        Other : this.state.Other,
        Category : 5,
        User: this.state.userList
      })
      });
        
    //map through heating checkbox list
    this.state.heatingCheckBoxList.map(e => {
      if(e.isChecked === true || e.itemChecked === 1)
      axios.post('https://portal.regional-services.com/api/insertScopesB', {  
        Scope : e.ScopeID,
        Other : this.state.Other,
        Category : 5,
        User: this.state.userList
        })
      });

      //map through welding checkboxlist
      this.state.weldingCheckBoxList.map(f => {
      if(f.isChecked === true || f.itemChecked === 1)
      axios.post('https://portal.regional-services.com/api/insertScopesB', {  
        Scope : f.ScopeID,
        Other : this.state.Other,
        Category : 5,
        User: this.state.userList
        })
      });
      //map through coatings checkboxlist
      this.state.coatingsCheckBoxList.map(g => {
        if(g.isChecked === true || g.itemChecked === 1)
        axios.post('https://portal.regional-services.com/api/insertScopesB', {  
          Scope : g.ScopeID,
          Other : this.state.Other,
          Category : 5,
          User: this.state.userList
        })
      });
      //map through chemical processing checkboxlist
      this.state.chemicalProcessingCheckBoxList.map(h => {
        if(h.isChecked === true || h.itemChecked === 1)
        axios.post('https://portal.regional-services.com/api/insertScopesB', {  
          Scope : h.ScopeID,
          Other : this.state.Other,
          Category : 5,
          User: this.state.userList
        })
      });
      //map through material testing checkboxlist
      this.state.materialTestingCheckBoxList.map(i => {
        if(i.isChecked === true || i.itemChecked === 1)
        axios.post('https://portal.regional-services.com/api/insertScopesB', {  
          Scope : i.ScopeID,
          Other : this.state.Other,
          Category : 5,
          User: this.state.userList
        }).then(() => {
          /* this.setState({
              revealState:{
                modalIsOpen : status
              }
            }); */
        })
      });
                          
    }).then(() => {
      this.setState({
        revealState:{
          modalIsOpen : status
        }
      });
    });
          
        
  }

  showPopup = (status) => {
    this.setState({
      revealState:{
        modalIsOpen : status
      }
    });
  }

  
  render() {
    
      return (
        
          <div>

            <div className="grid-x">
            <div class="cell small-12">
          <CheckboxComponent 
          checkboxList={this.state.checkboxList}  
          onChange={this.handleOnChange}
          />

          <label className={this.state.hidden}>Please Specify</label>
          <input type="text" name="Other" className={this.state.hidden} onChange={evt => this.updateInputValue(evt)} value={this.state.Other}/>

          <button className="button" onClick={()=>this.handleOnSubmit(true)}>Submit</button>

          </div>
          </div>
          <div className="grid-x grid-padding-x">
            <div className="cell">
              <h4>Sub Categories</h4>
              <p>To view the below options check the corresponding main NADCAP categories above</p>
            </div>
          </div>
          <div className="grid-x grid-padding-x small-up-2 medium-up-4 large-up-5">
          <div class="cell">
              <h5>Chemical Processing</h5>
              <div className={this.state.chemicalProcessingCheckBoxListHidden}>
                
                <p>Select which scopes apply</p>
                <CheckboxComponent 
                checkboxList={this.state.chemicalProcessingCheckBoxList}  
                onChange={this.handleOnChange}
                />
              </div>
              </div>
              <div class="cell">
              <h5>Coatings</h5>
              <div className={this.state.coatingsCheckBoxListHidden}>
                
                <p>Select which scopes apply</p>
                <CheckboxComponent 
                checkboxList={this.state.coatingsCheckBoxList}  
                onChange={this.handleOnChange}
                />
              </div>
              </div>
            <div class="cell">
            <h5>Heat Treatment</h5>
              <div className={this.state.heatingCheckBoxListHidden}>
                
                <p>Select which scopes apply</p>
                <CheckboxComponent 
                checkboxList={this.state.heatingCheckBoxList}  
                onChange={this.handleOnChange}
                />
              </div>
              </div>
              <div class="cell">
              <h5>Material Testing</h5>
              <div className={this.state.materialTestingCheckBoxListHidden}>
                
                <p>Select which scopes apply</p>
                <CheckboxComponent 
                checkboxList={this.state.materialTestingCheckBoxList}  
                onChange={this.handleOnChange}
                />
              </div>
            </div>
              <div class="cell">
              <h5>Welding</h5>
              <div className={this.state.weldingCheckBoxListHidden}>
                
                <p>Select which scopes apply</p>
                <CheckboxComponent 
                checkboxList={this.state.weldingCheckBoxList}  
                onChange={this.handleOnChange}
                />
              </div>
              </div>
              
              
              
            </div>

            <Modal 
                open={this.state.revealState.modalIsOpen}
                closeModal={this.showPopup}
                isModal={true}
                size="small"
                overlayStyle={overlayStyle}
                 >
                <h3>Entries Saved Successfully</h3>
                <p>Your scopes have been saved. Please make sure to check the other tabs to see if you have any other scopes in a different category.</p>
                <button className="button" type="button" onClick={() => this.showPopup(false)} >
                    Close
                </button>
            </Modal>
          </div>
          
      );
    
  }
}
export default App;