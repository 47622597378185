import { Component } from "react";
import Modal from 'react-foundation-modal';
import CheckboxComponent from "../utils/checkbox.component";
import axios from 'axios';
import Cookies from 'js-cookie';
import {Base64} from 'js-base64';

const overlayStyle = {
  'backgroundColor': 'rgba(0,0,0,0)'
  };



class App extends Component {
  constructor() {
    super();
    this.handleOnChange = this.handleOnChange.bind(this);
      this.state = 
      {
      checkboxList: [
        {
          
        },
      ],
      userList : '',
      revealState:{
        modalIsOpen : false
      }
      
    }; 
  }

  componentDidMount() {
    let cookieValue = Cookies.get('user') ;
    axios.post('https://portal.regional-services.com/api/getUserCN',{ //getUserTest
      num : cookieValue
    }).then((resp)=>{
      //console.log('user is '+resp.data);
      this.setState({userList : resp.data});
      axios.get('https://portal.regional-services.com/api/supCapScopes/7/'+resp.data+'').then((resp)=>{  //http://localhost:3001
      //console.log(resp.data); 
      this.setState({checkboxList: resp.data});
    });
    });
    
  }

  onChange = (event) => {
    //console.log(event); 
    this.setState({ selectedValue: event.target.value });
    
  };

  

  handleOnChange(event,option) {
    console.log('sub category user is: ' + this.state.userList);
    //console.log(event.target.checked);
    let checkboxList = this.state.checkboxList;
    checkboxList.forEach(chkItem=>{
      //console.log(chkItem);
      if(chkItem === option){
        
        chkItem.isChecked = event.target.checked;
        chkItem.itemChecked = 0;
      }
      
    })
    this.setState({
      checkboxList: checkboxList,
      //Other: event.target.value
    });
    
  }

  

  handleOnSubmit = (status) => {
    //console.table(this.state.checkboxList);
    
    //delete existing scopes
    axios.post('https://portal.regional-services.com/api/deleteScopes', {  
         Category : 7,
         User: this.state.userList
    }).then(()=>{

   

    //map through the checkboxlist state
    this.state.checkboxList.map(d => {
      //console.log(d.ScopeID)
      //console.log(this.state.Other)
      if(d.isChecked === true || d.itemChecked === 1)
       
       axios.post('https://portal.regional-services.com/api/insertScopesB', {  
         Scope : d.ScopeID,
         Other : this.state.Other,
         Category : 7,
         User: this.state.userList
        }).then(()=>{
        //alert('successfull Insert');
        this.setState({
          revealState:{
            modalIsOpen : status
          }
        });
        });

      });
      
    
      });
      
  }

  showPopup = (status) => {
    this.setState({
      revealState:{
        modalIsOpen : status
      }
    });
  }

  
  render() {
    
      return (
        
          <div>
          <CheckboxComponent 
          checkboxList={this.state.checkboxList}  
          onChange={this.handleOnChange}
          />

          

          <button className="button" onClick={()=>this.handleOnSubmit(true)}>Submit</button>

            <Modal 
                open={this.state.revealState.modalIsOpen}
                closeModal={this.showPopup}
                isModal={true}
                size="small"
                overlayStyle={overlayStyle}
                 >
                <h3>Entries Saved Successfully</h3>
                <p>Your scopes have been saved. Please make sure to check the other tabs to see if you have any other scopes in a different category.</p>
                <button className="button" type="button" onClick={() => this.showPopup(false)} >
                    Close
                </button>
            </Modal>
          </div>
          
      );
    
  }
}
export default App;